(function ($) { 'use strict';

    $(function () {
        
        //subscribe form footer
		$( "#subscribe_submit" ).click(function() {
        	$( "#formSubscribe" ).submit();
		});
        
    });

}(jQuery));