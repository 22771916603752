// Progress-bar onScroll for posts
var progressBar = null;
progressBar = document.getElementById("progressBar");
if ( progressBar != null ) {
	window.onscroll = function() { progressOnScroll() }
};

function progressOnScroll() {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  var scrolled = (winScroll / height) * 100;
  document.getElementById("progressBar").style.width = scrolled + "%";
};