//NPM
//import "@popperjs/core";
import "bootstrap";
import "slick-carousel";

//THEME
import defaults from "./defaults.js";
import navbar from "./navbar.js";
import footer from "./footer.js";
import tooltips from "./tooltips.js";

//GLOBAl USING LIbRARIES
import isotope from "./libs/isotope.js";
//import annimate from './annimate.js';
import slickFunctions from "./slickFunctions.js";
import parallax from "./parallax.js";
import progressBar from "./progressBar.js";

//font awesome
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

//VueJS
//import footer from './vue-scripts.js';
